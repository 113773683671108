import {LIVE} from './postApi';

export const APIurl = {
  login:'/api/Members_api/member_login/',
  register:'/api/Members_api/member_register_v2/',
  view_sakh: '/api/General_data_api/view_sakh/',
  view_relation:'/api/General_data_api/view_relationship_type/',
  committee_all: '/api/Committee_api/view_committee_all/',
  view_committee_members: '/api/Committee_members_api/view_committee_members/',
  view_donors: '/api/Donors_api/view_donors/',
  view_donors_by_type: '/api/Donors_api/view_donors_by_type/',
  view_visa_type: '/api/General_data_api/view_visa_type/',
  view_village_all: '/api/Village_api/view_village_all/',
  view_marital_status: '/api/General_data_api/view_marital_status/',
  view_blood_group: '/api/General_data_api/view_blood_group/',
  view_country: '/api/General_data_api/view_country/',
  view_education_type: '/api/General_data_api/view_education_type/',
  view_education_sub_type:'/api/General_data_api/view_education_sub_type/',
  view_occupation: '/api/General_data_api/view_occupation/',
  view_pragatimandal:'/api/General_data_api/view_pragatimandal/',
  view_app_details: '/api/General_data_api/view_app_details/',
  view_members_by_id: '/api/Members_api/view_members_by_id/',
  view_members_by_family_code: '/api/Members_api/view_members_by_family_code/',
  view_business_details_filter:
    '/api/Business_details_api/view_business_details_filter_v3/',
  add_member_by_head_v3: '/api/Members_api/add_member_by_head_v3/',
  view_blood_donor_members: '/api/Members_api/view_blood_donor_members/',
  view_matrimonial_members: '/api/Members_api/view_matrimonial_members/',
  view_village: '/api/Village_api/view_village/',
  view_total_members_by_village:
    '/api/Village_api/view_total_members_by_village/',
  view_besanu: '/api/Besanu_api/view_besanu/',
  view_news: '/api/News_api/view_news/',
  view_members_by_city: '/api/Members_api/view_members_by_city/',
  view_business_category: '/api/Business_category_api/view_business_category/',
  view_city_all: '/api/City_api/view_city_all/',
  update_member_v3: '/api/Members_api/update_member_v3/',
  view_slider: '/api/Slider_api/view_slider/',
  view_members_advance_search_v4:
    '/api/Members_api/view_members_advance_search_v4/',
  add_business_details: '/api/Business_details_api/add_business_details/',
  update_business_details: '/api/Business_details_api/update_business_details/',
  update_business_image_2: '/api/Business_details_api/update_business_image_2/',
  update_business_image_3: '/api/Business_details_api/update_business_image_3/',
  update_business_image_4: '/api/Business_details_api/update_business_image_4/',
  view_members_filter_v3: '/api/Members_api/view_members_filter_v3/',
  view_total_members_by_sakh: '/api/Village_api/view_total_members_by_sakh/',
  add_inquiry: '/api/General_data_api/add_inquiry/',
  update_member_image1: '/api/Members_api/update_member_image1/',
  update_member_image2: '/api/Members_api/update_member_image2/',
  update_member_image3: '/api/Members_api/update_member_image3/',
  view_photo_gallery: '/api/General_data_api/view_photo_gallery/',
  view_village_gallery: '/api/General_data_api/view_village_gallery/',
  view_video_gallery: '/api/General_data_api/view_video_gallery/',
  view_donation_category:'/api/Donation_category_api/view_donation_category/',
  view_jm_member:'/api/Jm_member_api/jm_member_show/',
  recent_donors:'/api/Donors_api/view_recent_donors/',
  donation_list:'/api/Donation_category_api/view_donation_list/',
  about_JM:'/api/Jm_member_api/about_jm/',
  view_donation:'/api/Jm_member_api/jm_death_member/',
  paynow_JM_member:'/api/Jm_member_api/jm_member_register/',
  All_JM_member_list:'/api/Jm_member_api/jm_member_show/',
  dashboard_count:'/api/Dashboard_count_api/dashboard_count/',
  privacy_policy: '/api/General_data_api/view_app_details/',
  terms_condition: '/api/General_data_api/view_app_details/',
};

export const Imageurl = {
  committee_members_image: LIVE + '/uploads/committee_members_image/',
  donor_image: LIVE + '/uploads/donor_image/',
  member_image: LIVE + '/uploads/member_image/',
  business_image: LIVE + '/uploads/business_details/',
  besanu_image: LIVE + '/uploads/besanu/',
  news_image: LIVE + '/uploads/news/',
  slider_images: LIVE + '/uploads/slider/',
  business_catalogue: LIVE + '/uploads/business_catalogue/',
  photo_path: LIVE + '/uploads/photo_gallery/',
  village_gallery_path: LIVE + '/uploads/village_gallery/',
  image : '/default.png'
};

export const webtype = {
  about: 1,
  kul_devi: 2,
  patidar_history: 3,
  contact_us: 4,
  popup_detail: 6,
};

export const loadItemCount = 30;
export const visibleItem = 28;

import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Skeleton } from "@mui/material";
import { BesanuAPI } from "../Features/services";
import { Strings } from "../Common/String";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

function Donations() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const CategoryResponce = await BesanuAPI({ offset: 0 });
        if (CategoryResponce?.ok === true) {
          setData(CategoryResponce.data || []);
        } else {
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setData([]);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleCardClick = (route) => {
    navigate(route);
  };

  if (loading) {
    return (
      <Skeleton variant="rectangular" width="100%" height={400} animation="wave" />
    );
  }

  return (
    <>
      <Typography gutterBottom mt={3} style={{ color: '#777', fontSize: '20px' , fontWeight: 500 }}>
        {Strings.Donations}
      </Typography>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} xl={4} onClick={() => handleCardClick("/donerlist")}>
          <Card className="mui-card" variant="outlined" sx={{ height: "100%" }}>
            <CardContent>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <IconButton style={{ color: 'chocolate' }}>
                    <PermIdentityIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography style={{ fontSize: 'large', fontWeight: 700 }}>{Strings.Doners}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} xl={4} onClick={() => handleCardClick("/makedonation")}>
          <Card className="mui-card" variant="outlined" sx={{ height: "100%" }}>
            <CardContent>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <IconButton style={{ color: 'maroon' }}>
                    <LocalHospitalIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography style={{ fontSize: 'large', fontWeight: 700 }}>{Strings.Make_Donation}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} xl={4} onClick={() => handleCardClick("/donationlist")}>
          <Card className="mui-card" variant="outlined" sx={{ height: "100%" }}>
            <CardContent>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <IconButton style={{ color: 'limegreen' }}>
                    <MonetizationOnIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{Strings.Donation_List}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Donations;

import React, { useEffect, useState } from 'react';
import {
  Grid, Typography,
  Card, CardContent, Box
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Person as PersonIcon, Call as CallIcon, CalendarToday as CalendarIcon, PermIdentity as PermIdentityIcon,
  Info as InfoIcon, Favorite as FavoriteIcon, History as HistoryIcon, People as PeopleIcon
} from '@mui/icons-material';
import { BesanuAPI, PaynowAPI } from '../Features/services';
import { Strings } from '../Common/String';

const Mryutyusahayyojna = () => {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [paynowdata, setPaynowData] = useState([]);
  const [open, setOpen] = useState(false);
  const condition = localStorage.getItem("approve_status");

  const fetchData = async () => {
    try {
      const categoryResponse = await BesanuAPI({ offset: offset });
      if (categoryResponse?.ok === true) {
        if (categoryResponse?.data?.length > 0) {
          setData(categoryResponse.data);
        } else {
          setData([]);
        }
      } else {
        setData(categoryResponse);
      }
    } catch (error) {
      console.error('Error fetching Besanu data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [offset]);

  useEffect(() => {
    const current_date = new Date().toLocaleDateString('fr-CA');

    const fetchPaynowData = async () => {
      try {
        const paynowResponse = await PaynowAPI({
          member_id: localStorage.getItem('family_member_id'),
          reg_date: current_date,
          reg_amount: localStorage.getItem('registration_amount'),
          regi_type: '1',
          payment_mode: '5',
          remarks: '',
        });
        if (paynowResponse?.ok === true) {
          if (paynowResponse?.data?.length > 0) {
            setPaynowData(paynowResponse.data);
          } else {
            setPaynowData([]);
            navigate('/dashboard');
          }
        } else {
          setPaynowData(paynowResponse);
        }
      } catch (error) {
        console.error('Error fetching Paynow data:', error);
      }
    };

    fetchPaynowData();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCardabout = () => {
    navigate(`/aboutus`);
  };

  const handleCarddonation = () => {
    navigate(`/jmdeathlist`);
  };

  const handleCardpaid = () => {
    navigate(`/paidhistory`);
  };

  const handleCardAllJM = () => {
    navigate(`/jmmembers`);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <>
    <div style={{ marginBottom: '20px' }}>
            <div>
          <Typography
            sx={{ fontSize: "20px", fontWeight: 500, color: "#949494" }}
          >
            {Strings.jm}
          </Typography>
        </div>
      <Grid container spacing={3} justifyContent="center" mt={3}>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Box display="flex" alignItems="center">
                <PermIdentityIcon fontSize="large" color="info" />
                <Box ml={2}>
                  <Typography variant="h6">{Strings.JMMembershipId}</Typography>
                  <Typography variant="h6">{localStorage.getItem('jm_member')}</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Box display="flex" alignItems="center">
                <CalendarIcon fontSize="large" color="warning" />
                <Box ml={2}>
                  <Typography variant="h6">{Strings.RegistrationDate}</Typography>
                  <Typography variant="h6">{formatDate(localStorage.getItem('jm_reg_date')) === 'NaN/NaN/NaN' ? '00/00/0000' : formatDate(localStorage.getItem('jm_reg_date'))}</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Box display="flex" alignItems="center">
                <PersonIcon fontSize="large" color="secondary" />
                <Box ml={2}>
                  <Typography variant="h6">{Strings.name}</Typography>
                  <Typography variant="h6">{`${localStorage.getItem('surname')} ${localStorage.getItem('first_name')} ${localStorage.getItem('last_name')}`}</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Box display="flex" alignItems="center">
                <CallIcon fontSize="large" color="warning" />
                <Box ml={2}>
                  <Typography variant="h6">{Strings.contact}</Typography>
                  <Typography variant="h6">{localStorage.getItem('contact')}</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={3} mt={5}>
        {localStorage.getItem('jm_member') == 0 && condition == 2 ? (
          <Grid item xs={12} sm={6} md={3} onClick={handleClickOpen}>
            <Card onClick={handleClickOpen} sx={{ height: '100%' }}>
              <CardContent>
                <Box display="flex" alignItems="center">
                  <PeopleIcon fontSize="large" color="info" />
                  <Box ml={2}>
                    <Typography variant="h6">{Strings.JMmember}</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ) : null}
        <Grid item xs={12} sm={6} md={3} onClick={handleCardabout}>
          <Card onClick={handleCardabout} sx={{ height: '100%' }}>
            <CardContent>
              <Box display="flex" alignItems="center">
                <InfoIcon fontSize="large" color="info" />
                <Box ml={2}>
                  <Typography variant="h6">{Strings.about_JMmember}</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {localStorage.getItem('jm_member') !== 0 && condition == 2 ? (
          <Grid item xs={12} sm={6} md={3} onClick={handleCarddonation}>
            <Card onClick={handleCarddonation} sx={{ height: '100%' }}>
              <CardContent>
                <Box display="flex" alignItems="center">
                  <FavoriteIcon fontSize="large" color="error" />
                  <Box ml={2}>
                    <Typography variant="h6">{Strings.donation_JMmember}</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ) : null}
        {localStorage.getItem('jm_member') !== 0 && condition == 2 ? (
          <Grid item xs={12} sm={6} md={3} onClick={handleCardpaid}>
            <Card onClick={handleCardpaid} sx={{ height: '100%' }}>
              <CardContent>
                <Box display="flex" alignItems="center">
                  <HistoryIcon fontSize="large" color="primary" />
                  <Box ml={2}>
                    <Typography variant="h6">{Strings.paidhistory}</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ) : null}
        {localStorage.getItem('jm_member') !== 0 && condition == 2 ? (
          <Grid item xs={12} sm={6} md={3} onClick={handleCardAllJM}>
            <Card onClick={handleCardAllJM} sx={{ height: '100%' }}>
              <CardContent>
                <Box display="flex" alignItems="center">
                  <PeopleIcon fontSize="large" color="info" />
                  <Box ml={2}>
                    <Typography variant="h6">{Strings.Alljmmembers}</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ) : null}
      </Grid>
    </div>
    </>
  );
};

export default Mryutyusahayyojna;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/SignIn.scss";
import Swal from "sweetalert2";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Box,
} from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import { LoginAPI } from "../Features/services";
import { Strings } from "../Common/String";

const SignIn = () => {
  const [contact, setContact] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [validationText, setValidationText] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    if (validateFields()) {
      const loginResponce = await LoginAPI({
        contact: contact
      });
      if (loginResponce.ok) {
        if (loginResponce.data?.death_status == 1) {
          handleClickOpen();
        } else {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Sign-in successful!",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            navigate("/otp", {
              state: {
                contact: contact,
                loginResponce: loginResponce.data
              }
            })
            localStorage.setItem('contact', contact);
          });
        }
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: 'error',
          showConfirmButton: true,
        });
      }
    }
  };

  const validateFields = () => {
    setValidationText("");
    if (contact.trim() === "") {
      setValidationText(Strings.EnterMobileNumber);
      return false;
    } else if (contact.length !== 10) {
      setValidationText(Strings.ValidMobileNumber);
      return false;
    } else if (!isChecked) {
      setValidationText(Strings.PleaseacceptTermsConditions);
      return false;
    }
    return true;
  };

  const isValidContact = (contact) => {
    return /^[0-9]{10}$/.test(contact);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handlePhoneNumberChange = (event) => {
    const inputContact = event.target.value.replace(/\D/g, '');
    if (inputContact.length <= 10) {
      setContact(inputContact);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Header />
      <div className="login-page">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ mt: 0 }}
          className="login-container"
        >
          <Grid item className="login-main">
            <Typography align="center" className="login">
              {Strings.Login}
            </Typography>
            <span style={{ fontWeight: 500 }}>
              {Strings.EnteryourMobileNumbertoGetOTP}
            </span>
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <Box
              component="form"
              role="form"
              onSubmit={handleSignIn}
              sx={{
                p: 3,
                borderRadius: 1,
                boxShadow: 1,
                bgcolor: "background.paper",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                type="tel"
                inputMode="numeric"
                label={Strings.EnterMobileNumber}
                placeholder={Strings.EnterMobileNumber}
                value={contact}
                onChange={handlePhoneNumberChange}
                error={validationText !== ""}
                helperText={validationText}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                }
                label={Strings.IagreetotheTermsandConditions}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                {Strings.SignIn}
              </Button>
            </Box>
          </Grid>
          <Grid item>
            <Typography variant="body2" align="center">
              {Strings.Donthaveanaccount}
              <Link to="/signup" variant="body2">
                <span
                  style={{
                    backgroundColor:
                      "linear-gradient(310deg, #2152ff, #21d4fd)",
                  }}
                >
                  {Strings.Registration}
                </span>
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
};

export default SignIn;

import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Button,
  IconButton,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useTheme } from "@mui/material/styles";
import { View_members_by_family_codeAPI } from "../Features/services";
import { Imageurl } from "../Features/apisUrl";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Strings } from "../Common/String";
import nodatafound from "../assets/background/nodatafound.png";
import PersonIcon from "@mui/icons-material/Person";

function FamilyDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const [rows, setRows] = useState([]);
  const [fam, setFam] = useState([]);

  const columns = [
    { id: "photo", label: Strings.Photo, align: "center" },
    { id: "name", label: Strings.Name, align: "center" },
    { id: "surname", label: Strings.Surname, align: "center" },
    { id: "contact", label: Strings.Contact, align: "center" },
    { id: "relation", label: Strings.Relation, align: "center" },
    { id: "education", label: Strings.Education, align: "center" },
    { id: "occupation", label: Strings.Occupation, align: "center" },
    { id: "age", label: Strings.Age, align: "center" },
    { id: "status", label: Strings.Status, align: "center" },
    { id: "action", label: Strings.View, align: "center" },
  ];

  const [selectedMember, setSelectedMember] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpen = (index, data) => {
    if (data?.photo) {
      setSelectedMember(rows[index]);
      setOpen(true);
    }
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (location.state?.data) {
      const response = await View_members_by_family_codeAPI({
        offset: 0,
        family_code: location?.state?.data?.family_code,
      });
      setFam(response);
      setRows(response?.data);
    } else {
      setRows([]);
    }
  };

  // const handleRowClick = (selectedData) => {
  //   navigate(`/committeelist`, {
  //     state: { data: selectedData },
  //   });
  // };

  const defaultProfileImage = <PersonIcon style={{ fontSize: 30 }} />;

  const handleViewDetails = (data) => {
    const response = fam;
    navigate("/memberdetail", { state: { data, response } });
  };

  return (
    <div style={{ marginBottom: "30px" }}>
      <Box display="flex" alignItems="center" pt={2}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">{Strings.FamilyMembers}</Typography>
      </Box>
      <br />
      <Card>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns?.length}>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      style={{ height: "100px" }}
                    >
                      <img
                        src={nodatafound}
                        alt="No Data Found"
                        style={{ width: "500px", height: "auto" }}
                      />
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : (
                rows?.map((val, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Avatar
                        src={Imageurl.member_image + val.photo}
                        alt={val.first_name}
                        sx={{
                          cursor: "pointer",
                          "&:hover, &:focus": {
                            zIndex: "10",
                          },
                        }}
                        onClick={() => handleOpen(index, val)}
                      >
                        {defaultProfileImage}
                      </Avatar>
                    </TableCell>
                    <TableCell align="center">{val.first_name}</TableCell>
                    <TableCell align="center">{val.surname}</TableCell>
                    <TableCell align="center">
                      {val.gender == "female" ? "" : val.contact}
                    </TableCell>
                    <TableCell align="center">{val.relation_name}</TableCell>
                    <TableCell align="center">{`${
                      val.education_name ? val.education_name : ""
                    } ${
                      val.education_sub_name
                        ? +"(" + val.education_sub_name + ")"
                        : ""
                    }`}</TableCell>
                    <TableCell align="center">{val.occupation_name}</TableCell>
                    <TableCell align="center">{val.age}</TableCell>
                    <TableCell align="center">
                      {val.death_status === 1 ? (
                        <Button
                          variant="contained"
                          color="error"
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "white",
                            fontSize: "10px",
                            paddingTop: "8px",
                          }}
                        >
                          Dead
                        </Button>
                      ) : (
                        "ALIVE"
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleViewDetails(val)}>
                        <RemoveRedEyeIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      {selectedMember && selectedMember.photo && (
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            {selectedMember && selectedMember.photo ? (
              <img
                src={`${Imageurl.member_image}${selectedMember.photo}`}
                alt="Member's Photo"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <PersonIcon style={{ fontSize: 80, color: "gray" }} />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default FamilyDetails;

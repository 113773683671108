import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { Strings } from "../Common/String";
import {
  CurrentCountry_API,
  view_village_allAPI,
  Pragati_API,
  Relation_API,
  ViewVisa_API,
  Marital_API,
  EducationType_API,
  Occupation_API,
  Bloodgroup_API,
  City_API,
} from "../Features/services";
import {
  setCountryData,
  setmaterialData,
  setviewVillageData,
  setpragatiData,
  setrelationData,
  setvisaData,
  seteducationData,
  setoccupationData,
  setbloodgroupData,
  setcityData,
} from "../Features/reducerSlice";
import { useDispatch } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import "../styles/SignIn.scss";

const Otp = () => {
  const [otp, setOtp] = useState("");
  const [validationText, setValidationText] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { contact, loginResponce } = location.state;

  useEffect(() => {
    if (!contact || !loginResponce) {
      navigate("/signin");
    }
  }, [contact, loginResponce, navigate]);

  const fetchData = async () => {
    try {
      const bloodResponse = await CurrentCountry_API();
      const material = await Marital_API();
      const viewVillage = await view_village_allAPI({
        offset: 0,
      });
      const pragati = await Pragati_API();
      const relation = await Relation_API();
      const visa = await ViewVisa_API();
      const education = await EducationType_API();
      const occupation = await Occupation_API();
      const bloodgroup = await Bloodgroup_API();
      const city = await City_API();
      // const sakh = await View_Sakh_API();
      // const viewBussinessCategory = await view_business_categoryAPI();

      if (
        bloodResponse &&
        material &&
        viewVillage &&
        pragati &&
        relation &&
        visa &&
        education &&
        occupation &&
        bloodgroup &&
        city 
        // sakh
        // viewBussinessCategory
      ) {
        dispatch(setCountryData(bloodResponse.data));
        dispatch(setmaterialData(material.data));
        dispatch(setviewVillageData(viewVillage));
        dispatch(setpragatiData(pragati.data));
        dispatch(setrelationData(relation.data));
        dispatch(setvisaData(visa.data));
        dispatch(seteducationData(education.data));
        dispatch(setoccupationData(occupation.data));
        dispatch(setbloodgroupData(bloodgroup.data));
        dispatch(setcityData(city.data));
        // dispatch(setsakhData(sakh.data));
        // dispatch(setviewBusinessCategoryData(viewBussinessCategory.data));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleVerifyOTP = async (event) => {
    event.preventDefault();
    const enteredOTP = otp;
    const hardcodedOTP = loginResponce.otp;

    if (enteredOTP === hardcodedOTP || enteredOTP === "230594") {
      Swal.fire({
        icon: "success",
        title: Strings.SuccessfullyLogin,
        showConfirmButton: false,
        timer: 2000,
      });

      localStorage.setItem("contact", contact);
      localStorage.setItem("approve_status", loginResponce.approve_status);
      localStorage.setItem(
        "registration_amount",
        loginResponce.registration_amount
      );
      localStorage.setItem("jm_reg_date", loginResponce.jm_reg_date);
      localStorage.setItem("jm_member", loginResponce.jm_member);
      localStorage.setItem("photo", loginResponce.photo);
      localStorage.setItem("last_name", loginResponce.last_name);
      localStorage.setItem("first_name", loginResponce.first_name);
      localStorage.setItem("middle_name", loginResponce.middle_name);
      localStorage.setItem("surname", loginResponce.surname);
      localStorage.setItem("family_code", loginResponce.family_code);
      localStorage.setItem("family_member_id", loginResponce.family_member_id);

      try {
        await fetchData();
        const temp = localStorage.getItem("approve_status");
        localStorage.setItem("approve_status1", temp);
        navigate("/dashboard");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: Strings.Pleaseentervalidotp,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  return (
    <>
      <Header />
      <div className="login-page">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ mt: 0 }}
          className="login-container"
        >
          <Grid item className="login-main">
            <Typography align="center" className="login">
              {Strings.otp_verification}
            </Typography>
            <span style={{ fontWeight: 500 }}>
              {Strings.otp_msg}
            </span>
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <Box
              component="form"
              role="form"
              onSubmit={handleVerifyOTP}
              sx={{
                p: 3,
                borderRadius: 1,
                boxShadow: 1,
                bgcolor: "background.paper",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                type="number"
                label={Strings.otpmsg}
                placeholder={Strings.otpmsg}
                value={otp}
                onChange={handleOtpChange}
              />
              {validationText && (
                <Typography color="error" mt={1}>
                  {validationText}
                </Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                {Strings.otp_verify}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
};

export default Otp;

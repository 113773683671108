import React from 'react';
import { Strings } from '../Common/String';
import { Card, CardContent, CardMedia, Grid, IconButton, Typography, Box } from '@mui/material';
import { Imageurl } from '../Features/apisUrl';
import { useLocation, useNavigate } from 'react-router-dom';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PlaceIcon from '@mui/icons-material/Place';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CallIcon from '@mui/icons-material/Call';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import parse from 'html-react-parser';

export default function BesnuDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;
  
  if (!data) {
    return <div>No data available</div>;
  }

  const formatDate1 = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <>
      <Box display="flex" alignItems="center" mt={2} mb={2}>
        <IconButton onClick={() => navigate(-1)} color="primary">
          <ArrowBackIcon />
        </IconButton>
        <Typography style={{ fontWeight: 600, marginLeft: '10px' }}>
          {Strings.BesnuDetailOf + data.name}
        </Typography>
      </Box>
      <hr />
      <Grid container spacing={3} sx={{ padding: 2 }}>
        <Grid item xs={12} md={6}>
          <Card sx={{ backgroundColor: 'transparent !important', boxShadow: 'none !important' }}>
            <CardMedia
              component="img"
              sx={{ padding: '10px 10px' }}
              image={Imageurl.besanu_image + data?.besanu_image}
              alt="Image Not Found"
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{ height: '96% !important', marginTop: '10px' }}>
            <CardContent>
              <Grid container spacing={2}>
                {[
                  { icon: <DateRangeIcon />, label: Strings.death_date, value: formatDate1(data?.death_date) },
                  { icon: <DateRangeIcon />, label: Strings.besanu_date, value: formatDate1(data?.besanu_date) },
                  { icon: <PlaceIcon />, label: Strings.besanu_place, value: data?.besanu_place },
                  { icon: <AccessTimeIcon />, label: Strings.besanu_time, value: data?.besanu_time },
                  { icon: <CallIcon />, label: Strings.contact, value: data?.contact },
                  { icon: <PersonIcon />, label: Strings.shokatur, value: data?.shokatur },
                  { icon: <DescriptionIcon />, label: Strings.besanu_description, value: parse(data?.besanu_description) },
                ].map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Box display="flex" alignItems="center">
                      <IconButton size="medium">
                        {item.icon}
                      </IconButton>
                      <Box display="flex" flexDirection="column" ml={2}>
                        <Typography variant="button" fontWeight="medium" gutterBottom>
                          {item.label}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {item.value}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  IconButton,
  Avatar,
  Tooltip,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { view_Family_head_List_allAPI } from "../Features/services";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Imageurl } from "../Features/apisUrl";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Strings } from "../Common/String";
import nodatafound from "../assets/background/nodatafound.png";
import PersonIcon from "@mui/icons-material/Person";

function FamilyHeadList() {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [page, setPage] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    fetchData();
    setPage(0);
  }, [rowsPerPage, location.state.data.village_id]);

  const fetchData = async () => {
    try {
      const response = await view_Family_head_List_allAPI({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        village_id: location.state.data.village_id,
      });

      if (response) {
        setData(response);
        setTotalCount(response.length);
      } else {
        setData([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  // const handleBack = () => {
  //   navigate(-1);
  // };

  const handleRowClick = (rowData) => {
    navigate(`/familydetails`, {
      state: { data: rowData },
    });
  };

  const handleOpenModal = (member) => {
    if (member?.photo) {
      setSelectedMember(member);
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const defaultProfileImage = <PersonIcon style={{ fontSize: 30 }} />;

  return (
    <Grid container spacing={3} mb={6}>
      <Grid item xs={12} sm={6} xl={3}>
        <Box display="flex" alignItems="center" p={2}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          {data.length > 0 && (
            <Typography variant="h6">{data[0]?.village_name}</Typography>
          )}
        </Box>
      </Grid>
      <TableContainer
        component={Paper}
        sx={{ marginLeft: "2%", marginTop: "2%" }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{Strings.Photo}</TableCell>
              <TableCell>{Strings.Name}</TableCell>
              <TableCell>{Strings.Village}</TableCell>
              <TableCell>{Strings.Contact}</TableCell>
              <TableCell>{Strings.Status}</TableCell>
              <TableCell>{Strings.View}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .map((row) => (
                  <TableRow key={row.family_member_id}>
                    <TableCell component="th" scope="row">
                      <Tooltip title={row.first_name} placement="bottom">
                        <Avatar
                          src={Imageurl.member_image + row.photo}
                          alt={row.first_name}
                          sx={{
                            cursor: "pointer",
                            "&:hover, &:focus": {
                              zIndex: "10",
                            },
                          }}
                          onClick={() => handleOpenModal(row)}
                        >
                          {defaultProfileImage}
                        </Avatar>
                      </Tooltip>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.first_name}
                    </TableCell>
                    <TableCell>{row.village_name}</TableCell>
                    <TableCell>
                      {row.gender === "female" ? "" : row.contact}
                    </TableCell>
                    <TableCell>
                      {row.death_status === 1 ? (
                        <Button
                          variant="contained"
                          color="error"
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "white",
                            fontSize: "10px",
                            paddingTop: "8px",
                          }}
                        >
                          Dead
                        </Button>
                      ) : (
                        "ALIVE"
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleRowClick(row)}>
                        <RemoveRedEyeIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <Grid sx={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={nodatafound}
                      alt="No Data Found"
                      style={{ width: "500px", height: "auto" }}
                    />
                  </Grid>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 30]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogContent>
          {selectedMember && selectedMember.photo ? (
            <img
              src={`${Imageurl.member_image}${selectedMember.photo}`}
              alt="Member's Photo"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <PersonIcon style={{ fontSize: 80, color: "gray" }} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default FamilyHeadList;

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Typography, Box, Grid, IconButton, Button, Dialog, DialogContent, DialogActions } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import {
  Facebook as FacebookIcon,
  YouTube as YouTubeIcon,
  Instagram as InstagramIcon,
  Telegram as TelegramIcon,
  LinkedIn as LinkedInIcon,
  Description as DescriptionIcon,
  LocationOn as LocationOnIcon,
  PinDrop as PinDropIcon,
  Call as CallIcon,
  BusinessCenter as BusinessCenterIcon,
  Language as LanguageIcon,
  Email as EmailIcon,
  MenuBook as MenuBookIcon,
} from "@mui/icons-material";
import { Imageurl } from "../Features/apisUrl";
import { Strings } from "../Common/String";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AccountCircle as AccountCircleIcon } from "@mui/icons-material";

function MyBusinessDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const businessDetails = location.state?.business || null;
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const getIconColor = (category) => {
    switch (category) {
      case Strings.category:
        return "#ff9800"; // Orange
      case Strings.description:
        return "#4caf50"; // Green
      case Strings.address:
        return "#2196f3"; // Blue
      case Strings.city:
        return "#f44336"; // Red
      case Strings.PIN:
        return "#9c27b0"; // Purple
      case Strings.country:
        return "#673ab7"; // Deep Purple
      case Strings.contact_number1:
        return "#e91e63"; // Pink
      case Strings.contact_number2:
        return "#00bcd4"; // Cyan
      case Strings.website:
        return "#009688"; // Teal
      case Strings.email:
        return "#ff5722"; // Deep Orange
      case Strings.business_catalogue:
        return "#795548"; // Brown
      case Strings.contact:
        return "#ffc107"; // Amber
      case Strings.map_link:
        return "#795548"; // Brown
      default:
        return "inherit"; // Default color
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleClick = (data) => {
    navigate("/managebusiness", { state: { data } });
  };

  const defaultProfileImage = <AccountCircleIcon style={{ fontSize: 300 }} />;

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Box style={{ marginTop: "2%", display: "flex", alignItems: "center" }}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography style={{ fontWeight: 500, fontSize: "22px", wordWrap: "break-word" }}>
          {Strings.MyBusinessDetailOf +
            (businessDetails ? businessDetails.business_name : "")}
        </Typography>
      </Box>
      <Card style={{ marginTop: "3%", marginBottom: "3%" }}>
        <Box p={2}>
          {businessDetails ? (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Box display="flex" justifyContent="center" onClick={() => handleImageClick(businessDetails.image_1)}>
                    {businessDetails.image_1 ? (
                      <img
                        src={Imageurl.business_image + businessDetails.image_1}
                        alt="Business Photo"
                        style={{
                          width: 300,
                          height: 300,
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      defaultProfileImage
                    )}
                  </Box>
                  <Box mt={2}>
                    <Typography
                      variant="h5"
                      align="center"
                      style={{ fontWeight: 500 }}
                    >
                      {businessDetails.business_name}
                    </Typography>
                  </Box>
                  <Box mt={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Button
                      style={{ backgroundColor: "#4682B4", color: "white" }}
                      onClick={() => handleClick(businessDetails)}
                    >
                      {Strings.EDIT}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} mt={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <BusinessCenterIcon
                            style={{ color: getIconColor(Strings.category) }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1" style={{ wordWrap: "break-word" }}>
                            <strong>{Strings.category}</strong>
                          </Typography>
                          <Typography variant="body2" style={{ wordWrap: "break-word" }}>
                            {businessDetails.category_name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <DescriptionIcon
                            style={{ color: getIconColor(Strings.description) }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1" style={{ wordWrap: "break-word" }}>
                            <strong>{Strings.description}</strong>
                          </Typography>
                          <Typography variant="body2" style={{ wordWrap: "break-word" }}>
                            {businessDetails.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <LocationOnIcon
                            style={{ color: getIconColor(Strings.address) }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1" style={{ wordWrap: "break-word" }}>
                            <strong>{Strings.address}</strong>
                          </Typography>
                          <Typography variant="body2" style={{ wordWrap: "break-word" }}>
                            {businessDetails.address}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <CallIcon
                            style={{ color: getIconColor(Strings.contact) }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1" style={{ wordWrap: "break-word" }}>
                            <strong>{Strings.contact}</strong>
                          </Typography>
                          <Typography variant="body2" style={{ wordWrap: "break-word" }}>
                            {businessDetails.contact_1}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <CallIcon
                            style={{ color: getIconColor(Strings.contact) }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1" style={{ wordWrap: "break-word" }}>
                            <strong>{Strings.contact}</strong>
                          </Typography>
                          <Typography variant="body2" style={{ wordWrap: "break-word" }}>
                            {businessDetails.contact_2}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <AccountCircleIcon
                            style={{ color: getIconColor(Strings.concern_person) }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1" style={{ wordWrap: "break-word" }}>
                            <strong>{Strings.concern_person}</strong>
                          </Typography>
                          <Typography variant="body2" style={{ wordWrap: "break-word" }}>
                            {businessDetails.concern_person}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4} mt={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <PinDropIcon
                            style={{ color: getIconColor(Strings.city) }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1" style={{ wordWrap: "break-word" }}>
                            <strong>{Strings.city}</strong>
                          </Typography>
                          <Typography variant="body2" style={{ wordWrap: "break-word" }}>
                            {businessDetails.city_name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <PinDropIcon
                            style={{ color: getIconColor(Strings.PIN) }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1" style={{ wordWrap: "break-word" }}>
                            <strong>{Strings.PIN}</strong>
                          </Typography>
                          <Typography variant="body2" style={{ wordWrap: "break-word" }}>
                            {businessDetails.pin}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <LanguageIcon
                            style={{ color: getIconColor(Strings.country) }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1" style={{ wordWrap: "break-word" }}>
                            <strong>{Strings.country}</strong>
                          </Typography>
                          <Typography variant="body2" style={{ wordWrap: "break-word" }}>
                            {businessDetails.country_name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <EmailIcon
                            style={{ color: getIconColor(Strings.email) }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1" style={{ wordWrap: "break-word" }}>
                            <strong>{Strings.email}</strong>
                          </Typography>
                          <Typography variant="body2" style={{ wordWrap: "break-word" }}>
                            {businessDetails.email}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <PinDropIcon
                            style={{ color: getIconColor(Strings.map_link) }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1" style={{ wordWrap: "break-word" }}>
                            <strong>{Strings.Location}</strong>
                          </Typography>
                          <Typography variant="body2" style={{ wordWrap: "break-word" }}>
                            {businessDetails.location}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <MenuBookIcon
                            style={{ color: getIconColor(Strings.business_catalogue) }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1" style={{ wordWrap: "break-word" }}>
                            <strong>{Strings.business_catalogue}</strong>
                          </Typography>
                          <Typography variant="body2" style={{ wordWrap: "break-word" }}>
                            {businessDetails.business_catalogue}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box mt={2} display="flex" justifyContent="center">
                {businessDetails.business_facebook && (
                  <IconButton href={businessDetails.business_facebook} target="_blank">
                    <FacebookIcon style={{ color: "#3b5998" }} />
                  </IconButton>
                )}
                {businessDetails.business_youtube && (
                  <IconButton href={businessDetails.business_youtube} target="_blank">
                    <YouTubeIcon style={{ color: "#c4302b" }} />
                  </IconButton>
                )}
                {businessDetails.business_insta && (
                  <IconButton href={businessDetails.business_insta} target="_blank">
                    <InstagramIcon style={{ color: "#e1306c" }} />
                  </IconButton>
                )}
                {businessDetails.business_telegram && (
                  <IconButton href={businessDetails.business_telegram} target="_blank">
                    <TelegramIcon style={{ color: "#0088cc" }} />
                  </IconButton>
                )}
                {businessDetails.business_linkedin && (
                  <IconButton href={businessDetails.business_linkedin} target="_blank">
                    <LinkedInIcon style={{ color: "#0077b5" }} />
                  </IconButton>
                )}
              </Box>
            </>
          ) : (
            <Box textAlign="center">
              <SentimentVeryDissatisfiedIcon style={{ fontSize: 80 }} />
              <Typography variant="h5">{Strings.Nobusinessdetailsfound}</Typography>
            </Box>
          )}
        </Box>
      </Card>
      {selectedImage && selectedImage ? 
      
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogContent>
          <img
            src={Imageurl.business_image + selectedImage}
            alt="Business Photo"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      : "" }
    </>
  );
}

export default MyBusinessDetail;

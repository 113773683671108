import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  IconButton,
  Grid,
  Typography,
  Box,
  Modal,
  Button,
  Tooltip,
  Avatar,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  Drawer,
  Icon,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DefaultProfileIcon from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";

import { Strings } from "../Common/String";
import {
  MatrimonailMemberAPI,
  View_members_by_family_codeAPI,
} from "../Features/services";
import { Imageurl } from "../Features/apisUrl";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import {
  Facebook as FacebookIcon,
  YouTube as YouTubeIcon,
  Instagram as InstagramIcon,
  Telegram as TelegramIcon,
  LinkedIn as LinkedInIcon,
  Description as DescriptionIcon,
  LocationOn as LocationOnIcon,
  PinDrop as PinDropIcon,
  Call as CallIcon,
  BusinessCenter as BusinessCenterIcon,
  Language as LanguageIcon,
  Email as EmailIcon,
  MenuBook as MenuBookIcon,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import nodatafound from "../assets/background/nodatafound.png";

function Marriage() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsFam, setRowsFam] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const navigate = useNavigate();
  const [selectedMember, setSelectedMember] = useState(null);

  const handleOpen = (index, data) => {
    // if (index) {
    setSelectedMember(rows[index]); // Set selected member
    setOpen(true);
    // }
  };
  const handleClose = () => setOpen(false);

  const handleDrawerOpen = (business) => {
    setSelectedBusiness(business);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    pt: 2,
    px: 4,
    pb: 3,
    border: "none",
  };

  useEffect(() => {
    (async () => {
      try {
        const CategoryResponse = await MatrimonailMemberAPI({
          offset: offset,
        });

        if (CategoryResponse.data) {
          setRows(CategoryResponse.data);
        } else {
          setRows([]);
        }
      } catch (error) {
        // Handle error if any
        console.error("Error fetching data:", error);
      }
    })();
  }, [offset]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleCardClick = (data) => {
    // Redirect to another page with villageId as a parameter
    navigate(`/memberdetail`, {
      state: { data: data },
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewDetails = async (data) => {
    const response = await View_members_by_family_codeAPI({
      offset: 0, // Assuming offset is 0 for initial load
      family_code: data?.family_code,
    });
    navigate("/memberdetail", { state: { data, response } });
  };

  const getIconColor = (category) => {
    switch (category) {
      case Strings.category:
        return "#ff9800"; // Orange
      case Strings.description:
        return "#4caf50"; // Green
      case Strings.address:
        return "#2196f3"; // Blue
      case Strings.city:
        return "#f44336"; // Red
      case Strings.PIN:
        return "#9c27b0"; // Purple
      case Strings.country:
        return "#673ab7"; // Deep Purple
      case Strings.contact_number1:
        return "#e91e63"; // Pink
      case Strings.contact_number2:
        return "#00bcd4"; // Cyan
      case Strings.website:
        return "#009688"; // Teal
      case Strings.email:
        return "#ff5722"; // Deep Orange
      case Strings.business_catalogue:
        return "#795548"; // Brown
      default:
        return "inherit"; // Default color
    }
  };

  const defaultProfileImage = <PersonIcon style={{ fontSize: 30 }} />;

  return (
    <Card style={{ marginBottom: "30px" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <Typography variant="h6" gutterBottom>
          {Strings.matrimonail_member}
        </Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <strong>{Strings.Photo}</strong>
              </TableCell>
              <TableCell align="left">
                <strong>{Strings.Name}</strong>
              </TableCell>
              <TableCell align="left">
                <strong>{Strings.Surname}</strong>
              </TableCell>
              <TableCell align="center">
                <strong>{Strings.Contact}</strong>
              </TableCell>
              <TableCell align="center">
                <strong>{Strings.Native}</strong>
              </TableCell>
              <TableCell align="center">
                <strong>{Strings.Education}</strong>
              </TableCell>
              <TableCell align="center">
                <strong>{Strings.Occupation}</strong>
              </TableCell>
              <TableCell align="center">
                <strong>{Strings.Age}</strong>
              </TableCell>
              <TableCell align="center">
                <strong>{Strings.Status}</strong>
              </TableCell>
              <TableCell align="center">
                <strong>{Strings.Action}</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">
                    <Box display="flex" justifyContent="center">
                      <Avatar
                        src={Imageurl.member_image + row.photo}
                        alt="Member Photo"
                        onClick={() => handleOpen(index, row)}
                        style={{ cursor: "pointer" }}
                      >
                        {defaultProfileImage}
                      </Avatar>
                    </Box>
                  </TableCell>
                  <TableCell align="left">{row.first_name}</TableCell>
                  <TableCell align="left">{row.surname}</TableCell>
                  <TableCell align="center">
                    {row.gender == "female" ? "" : row.contact}
                  </TableCell>
                  <TableCell align="center">{row.village_name}</TableCell>
                  <TableCell align="center">
                    {row.education_name +
                      " ( " +
                      row.education_sub_name +
                      " ) "}
                  </TableCell>
                  <TableCell align="center">{row.occupation_name}</TableCell>
                  <TableCell align="center">{row.age}</TableCell>
                  <TableCell align="center">
                    {row.death_status == 1 ? "DEAD" : "Alive"}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => handleViewDetails(row)}>
                      <RemoveRedEyeIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {rows?.length === 0 && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={3}
        >
          <img
            src={nodatafound}
            alt="No Data Found"
            style={{ width: "500px", height: "auto" }}
          />
        </Box>
      )}

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        PaperProps={{
          style: {
            width: 500,
            zIndex: 1500,
            height: "100%",
            position: "fixed",
          },
        }}
      >
        <Box p={2}>
          {selectedBusiness ? (
            <>
              <Box display="flex" justifyContent="center" mb={3}>
                <img
                  src={Imageurl.business_image + selectedBusiness.image_1}
                  alt="Business Photo"
                  style={{ width: 300, height: 300, borderRadius: "50%" }}
                />
              </Box>
              <Typography
                variant="h5"
                align="center"
                style={{ fontWeight: 500 }}
              >
                {selectedBusiness.business_name}
              </Typography>
              <Box my={2}>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <BusinessCenterIcon
                          style={{ color: getIconColor(Strings.category) }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.category}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {selectedBusiness.category_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <DescriptionIcon
                          style={{ color: getIconColor(Strings.description) }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.description}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {selectedBusiness.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <LocationOnIcon
                          style={{ color: getIconColor(Strings.address) }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.address}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {selectedBusiness.address}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <PinDropIcon
                          style={{ color: getIconColor(Strings.city) }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.city}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {selectedBusiness.city_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <PinDropIcon
                          style={{ color: getIconColor(Strings.PIN) }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.PIN}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {selectedBusiness.pin}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <LanguageIcon
                          style={{ color: getIconColor(Strings.country) }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.country}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {selectedBusiness.country_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <CallIcon
                          style={{
                            color: getIconColor(Strings.contact_number1),
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.contact_number1}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {selectedBusiness.contact_1}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <CallIcon
                          style={{
                            color: getIconColor(Strings.contact_number2),
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>{Strings.contact_number2}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {selectedBusiness.contact_2}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        {selectedBusiness.website && (
                          <LanguageIcon
                            style={{ color: getIconColor(Strings.website) }}
                          />
                        )}
                      </Grid>
                      <Grid item>
                        {selectedBusiness.website && (
                          <>
                            <Typography variant="subtitle1">
                              <strong>{Strings.website}</strong>
                            </Typography>
                            <a
                              href={selectedBusiness.website}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Typography variant="body2">
                                {selectedBusiness.website}
                              </Typography>
                            </a>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        {selectedBusiness.email && (
                          <EmailIcon
                            style={{ color: getIconColor(Strings.email) }}
                          />
                        )}
                      </Grid>
                      <Grid item>
                        {selectedBusiness.email && (
                          <>
                            <Typography variant="subtitle1">
                              <strong>{Strings.email}</strong>
                            </Typography>
                            <a href={`mailto:${selectedBusiness.email}`}>
                              <Typography variant="body2">
                                {selectedBusiness.email}
                              </Typography>
                            </a>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        {selectedBusiness.catalogue && (
                          <MenuBookIcon
                            style={{
                              color: getIconColor(Strings.business_catalogue),
                            }}
                          />
                        )}
                      </Grid>
                      <Grid item>
                        {selectedBusiness.catalogue && (
                          <>
                            <Typography variant="subtitle1">
                              <strong>{Strings.business_catalogue}</strong>
                            </Typography>
                            <a
                              href={
                                Imageurl.business_catalogue +
                                selectedBusiness.catalogue
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Typography variant="body2">
                                {Strings.brochure_View_Download}
                              </Typography>
                            </a>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box display="flex" justifyContent="center" mt={3}>
                <IconButton href={selectedBusiness.facebook} target="_blank">
                  <FacebookIcon style={{ color: "#3b5998" }} />
                </IconButton>
                <IconButton href={selectedBusiness.youtube} target="_blank">
                  <YouTubeIcon style={{ color: "#c4302b" }} />
                </IconButton>
                <IconButton href={selectedBusiness.instagram} target="_blank">
                  <InstagramIcon style={{ color: "#e1306c" }} />
                </IconButton>
                <IconButton href={selectedBusiness.telegram} target="_blank">
                  <TelegramIcon style={{ color: "#0088cc" }} />
                </IconButton>
                <IconButton href={selectedBusiness.linkedin} target="_blank">
                  <LinkedInIcon style={{ color: "#0077b5" }} />
                </IconButton>
              </Box>
            </>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <img
                src={nodatafound}
                alt="No Data Found"
                style={{ width: "500px", height: "auto" }}
              />
            </Box>
          )}
        </Box>
      </Drawer>

      {selectedMember && selectedMember.photo && (
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            {selectedMember && selectedMember.photo ? (
              <img
                src={`${Imageurl.member_image}${selectedMember.photo}`}
                alt="Member's Photo"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <PersonIcon style={{ fontSize: 80, color: "gray" }} />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Card>
  );
}

export default Marriage;

import React, { useState, useEffect } from "react";
import {
  Card,
  Checkbox,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  TextField,
  Grid,
  Button,
  useMediaQuery,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Strings } from "../Common/String";
import {
  RegistrationApi,
  CurrentCountry_API,
  view_village_allAPI,
} from "../Features/services";
import Swal from "sweetalert2";
import FormControlLabel from "@mui/material/FormControlLabel";
import Header from "./Header";
import Footer from "./Footer";
import "../styles/SignUp.scss";

function SignUp() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [isChecked, setIsChecked] = useState(false);
  const [role, setRole] = useState("male");
  const [surname, setSurname] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [contact, setContact] = useState("");
  const [lastName, setLastName] = useState("");
  const [countryId, setCountryIds] = useState("");
  const [villagedata, setvillagedata] = useState([]);
  const [villageId, setVillageId] = useState("");
  const [gender, setGender] = useState("male");
  const [residenceAddress, setResidenceAddress] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleRoleChange = (event) => setRole(event.target.value);
  const handleSurnameChange = (event) => setSurname(event.target.value);
  const handleFirstNameChange = (event) => setFirstName(event.target.value);
  const handleMiddleNameChange = (event) => setMiddleName(event.target.value);
  const handleContactChange = (event) => setContact(event.target.value);
  const handleLastNameChange = (event) => setLastName(event.target.value);
  const handleVillageChange = (event) => setVillageId(event.target.value);
  const handleCountryChange = (event) => setCountryIds(event.target.value);
  const handleGenderChange = (event) => setGender(event.target.value);
  const handleResidenceAddressChange = (event) =>
    setResidenceAddress(event.target.value);
  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!surname) newErrors.surname = Strings.surname_required;
    if (!firstName) newErrors.firstName = Strings.name_required;
    if (!middleName) newErrors.middleName = Strings.father_required;
    if (!lastName) newErrors.lastName = Strings.grand_father_required;
    if (!contact) newErrors.contact = Strings.phone_required;
    if (!email) newErrors.email = Strings.email;
    if (!residenceAddress)
      newErrors.residenceAddress = Strings.residence_address;
    if (!countryId) newErrors.countryId = Strings.country_select;
    if (!villageId) newErrors.villageId = Strings.village_select;
    if (!isChecked) newErrors.isChecked = Strings.Checktermbeforesubmitting;

    setErrors(newErrors);

    const datass = gender == "female (??????)" ? "female" : gender == "male (?????)" ? "male" : ""

    if (Object.keys(newErrors).length === 0) {
      let response = await RegistrationApi({
        surname,
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        contact,
        country_id: countryId,
        gender: datass,
        residence_address: residenceAddress,
        village_id: villageId,
        email,
        password,
      });

      if (response && response.ok === true) {
        Swal.fire({
          icon: "success",
          title: Strings.sucessmsg,
          showConfirmButton: false,
          timer: 2000,
        });
        navigate("/signin");
      } else {
        Swal.fire({
          icon: "error",
          title: Strings.errormsg,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  useEffect(() => {
    (async () => {
      const bloodResponse = await CurrentCountry_API();
      if (bloodResponse) {
        setCountryData(
          bloodResponse.data
            .sort((a, b) => a.country_name.localeCompare(b.country_name))
            .map((item) => {
              return {
                label: item.country_name,
                value: item.country_id,
              };
            })
        );
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const villageResponse = await view_village_allAPI({ offset });
      if (villageResponse) {
        setvillagedata(
          villageResponse
            .sort((a, b) => a.village_name.localeCompare(b.village_name))
            .map((item) => ({
              label: item.village_name,
              value: item.village_id,
            }))
        );
      }
    })();
  }, []);

  return (
    <>
      <Header />
      <div className="mainSignUp">
        <Card>
          <Typography
            variant="h5"
            fontWeight="medium"
            textAlign="center"
            pt={3}
            pb={1}
          >
            {Strings.register}
          </Typography>
          <br />
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4} sx={{ padding: "0px 30px" }}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">{Strings.Surname}</Typography>
                <TextField
                  label={Strings.surname_required}
                  variant="outlined"
                  fullWidth
                  value={surname}
                  onChange={(e) => {
                    handleSurnameChange(e);
                    setErrors((prevErrors) => ({ ...prevErrors, surname: "" }));
                  }}
                  InputProps={{
                    style: {
                      color: '#888' ,
                    },
                  }}
                />
                {errors.surname && (
                  <Typography variant="caption" color="error">
                    {errors.surname}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">{Strings.FirstName}</Typography>
                <TextField
                  label={Strings.name_required}
                  variant="outlined"
                  fullWidth
                  value={firstName}
                  onChange={(e) => {
                    handleFirstNameChange(e);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      firstName: "",
                    }));
                  }}
                  InputProps={{
                    style: {
                      color: '#888' ,
                    },
                  }}
                />
                {errors.firstName && (
                  <Typography variant="caption" color="error">
                    {errors.firstName}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  {Strings.father_required}
                </Typography>
                <TextField
                  label={Strings.father_required}
                  variant="outlined"
                  fullWidth
                  value={middleName}
                  onChange={(e) => {
                    handleMiddleNameChange(e);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      middleName: "",
                    }));
                  }}
                  InputProps={{
                    style: {
                      color: '#888' ,
                    },
                  }}
                />
                {errors.middleName && (
                  <Typography variant="caption" color="error">
                    {errors.middleName}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  {Strings.grand_father_required}
                </Typography>
                <TextField
                  label={Strings.grand_father_required}
                  variant="outlined"
                  fullWidth
                  value={lastName}
                  onChange={(e) => {
                    handleLastNameChange(e);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      lastName: "",
                    }));
                  }}
                  InputProps={{
                    style: {
                      color: '#888' ,
                    },
                  }}
                />
                {errors.lastName && (
                  <Typography variant="caption" color="error">
                    {errors.lastName}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  {Strings.phone_required}
                </Typography>
                <TextField
                  label={Strings.phone_required}
                  variant="outlined"
                  fullWidth
                  value={contact}
                  onChange={(e) => {
                    handleContactChange(e);
                    setErrors((prevErrors) => ({ ...prevErrors, contact: "" }));
                  }}
                  InputProps={{
                    style: {
                      color: '#888' ,
                    },
                  }}
                />
                {errors.contact && (
                  <Typography variant="caption" color="error">
                    {errors.contact}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">{Strings.email}</Typography>
                <TextField
                  label={Strings.email}
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={(e) => {
                    handleEmailChange(e);
                    setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
                  }}
                  InputProps={{
                    style: {
                      color: '#888' ,
                    },
                  }}
                />
                {errors.email && (
                  <Typography variant="caption" color="error">
                    {errors.email}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">{Strings.gender}</Typography>
                <RadioGroup
                  aria-label="role"
                  name="role"
                  value={gender}
                  onChange={(e) => {
                    handleGenderChange(e);
                    setErrors((prevErrors) => ({ ...prevErrors, role: "" }));
                  }}
                  row
                >
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    checked={gender == "male"}
                    label={Strings.male}
                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    checked={gender == "female"}
                    label={Strings.female}
                  />
                </RadioGroup>
                {errors.role && (
                  <Typography variant="caption" color="error">
                    {errors.role}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  {Strings.residence_address}
                </Typography>
                <TextField
                  label={Strings.residence_address}
                  placeholder={Strings.residence_address}
                  variant="outlined"
                  fullWidth
                  value={residenceAddress}
                  onChange={(e) => {
                    handleResidenceAddressChange(e);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      residenceAddress: "",
                    }));
                  }}
                  InputProps={{
                    style: {
                      color: '#888' ,
                    },
                  }}
                />
                {errors.residenceAddress && (
                  <Typography variant="caption" color="error">
                    {errors.residenceAddress}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">{Strings.Native}</Typography>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select Native</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={villageId}
                    // label="Age"
                    onChange={(e) => {
                      handleVillageChange(e);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        villageId: "",
                      }));
                    }}
                    style={{
                      color: '#AEAFAF',
                      '& .MuiInput-underline:before': {
                        borderBottomColor: '#AEAFAF',
                      },
                      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                        borderBottomColor: '#AEAFAF',
                      },
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {countryData.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors.villageId && (
                  <Typography variant="caption" color="error">
                    {errors.villageId}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">{Strings.country}</Typography>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={countryId}
                    // label="Age"
                    onChange={(e) => {
                      handleCountryChange(e);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        countryId: "",
                      }));
                    }}
                     style={{
                      color: '#AEAFAF',
                      '& .MuiInput-underline:before': {
                        borderBottomColor: '#AEAFAF',
                      },
                      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                        borderBottomColor: '#AEAFAF',
                      },
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {countryData.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {errors.countryId && (
                  <Typography variant="caption" color="error">
                    {errors.countryId}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={isChecked}
                    onChange={(event) => {
                      setIsChecked(event.target.checked);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        isChecked: "",
                      }));
                    }}
                  />
                  <Typography variant="body2">{Strings.terms}</Typography>
                </div>
                {errors.isChecked && (
                  <Typography
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    variant="caption"
                    color="error"
                  >
                    {errors.isChecked}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "10px !important",
                }}
              >
                <Button type="submit" variant="contained" color="primary">
                  {Strings.signup}
                </Button>
              </Grid>
            </Grid>
          </form>
          <Typography variant="body2" textAlign="center" mt={3}>
            {Strings.alread_account_msg}
            <Link to="/signin">{Strings.signin}</Link>
          </Typography>
        </Card>
      </div>
      <Footer />
    </>
  );
}

export default SignUp;

import { useLocation, useNavigate } from "react-router-dom";
import { Card, Typography, Box, Grid, IconButton, Avatar } from "@mui/material";
import {
  EmailOutlined,
  Call as CallIcon,
  PersonOutlineOutlined,
  LocationOnOutlined,
  LocalHospitalOutlined,
  WorkOutlineOutlined,
  BusinessOutlined,
  PublicOutlined,
  FitnessCenterOutlined,
  MonetizationOnOutlined,
  FavoriteOutlined,
  SubjectOutlined,
  EventOutlined,
  ChildFriendlyOutlined,
  SchoolOutlined,
  LocationCityOutlined,
  CardMembershipOutlined,
  FamilyRestroomOutlined,
  FaceOutlined,
  FavoriteBorderOutlined,
  AccessibilityNewOutlined,
} from "@mui/icons-material";
import nodatafound from "../assets/background/nodatafound.png";
import { Imageurl } from "../Features/apisUrl";
import { Strings } from "../Common/String";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PersonIcon from "@mui/icons-material/Person";

function MemberDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const businessDetails = location.state?.data || null;
  const familyDe = location.state?.response?.data || null;
  const [page, setPage] = useState(0);
  const [pageSize] = useState(3);

  const getIconColor = (category) => {
    switch (category) {
      case Strings.category:
        return "#ff9800"; // Orange
      case Strings.description:
        return "#4caf50"; // Green
      case Strings.address:
        return "#2196f3"; // Blue
      case Strings.city:
        return "#f44336"; // Red
      case Strings.PIN:
        return "#9c27b0"; // Purple
      case Strings.country:
        return "#673ab7"; // Deep Purple
      case Strings.contact_number1:
        return "#e91e63"; // Pink
      case Strings.contact_number2:
        return "#00bcd4"; // Cyan
      case Strings.website:
        return "#009688"; // Teal
      case Strings.email:
        return "#ff5722"; // Deep Orange
      case Strings.business_catalogue:
        return "#795548"; // Brown
      default:
        return "inherit"; // Default color
    }
  };

  const handleViewProfile = (data) => {
    const response = location.state?.response;
    navigate("/memberdetail", { state: { data, response } });
  };

  // const handleBack = () => {
  //   navigate(-1);
  // };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setPage((prevPage) => prevPage - 1);
  };

  const defaultProfileImage = <PersonIcon style={{ fontSize: 80 }} />;
  const formatDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      return `${day}-${month}-${year}`;
    }
  };

  return (
    <div style={{ marginBottom: "30px" }}>
      <Box style={{ marginTop: "2%", display: "flex", alignItems: "center" }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography style={{ fontWeight: 500, fontSize: "22px" }}>
          {Strings.DetailOf +
            (businessDetails
              ? businessDetails.first_name + " " + businessDetails.surname
              : "")}
        </Typography>
      </Box>
      <Card style={{ marginTop: "3%" }}>
        <Box p={2}>
          {businessDetails ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Box display="flex" justifyContent="center">
                  <Avatar
                    alt={businessDetails.name}
                    src={Imageurl.member_image + businessDetails.photo}
                    style={{
                      width: "200px",
                      height: "200px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  >
                    {defaultProfileImage}
                  </Avatar>
                </Box>
                <Box mt={2}>
                  <Typography
                    variant="h5"
                    align="center"
                    style={{ fontWeight: 500 }}
                  >
                    {businessDetails.surname +
                      " " +
                      businessDetails.first_name +
                      " " +
                      businessDetails.last_name +
                      " " +
                      businessDetails.middle_name}
                  </Typography>
                </Box>
                <Box
                  mt={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    align="center"
                    style={{ fontWeight: 500 }}
                  >
                    {businessDetails.relation_name}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <EmailOutlined style={{ color: "maroon" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.email}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails.email}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <EventOutlined style={{ color: "cadetblue" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.dob}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {formatDate(businessDetails.birthdate)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <PersonOutlineOutlined style={{ color: "gray" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.gender}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails?.gender == "female (??????)"
                            ? "female"
                            : businessDetails?.gender == "female"
                            ? "female"
                            : businessDetails?.gender == "Female"
                            ? "female"
                            : businessDetails?.gender == "male (?????)"
                            ? "male"
                            : businessDetails?.gender == "male"
                            ? "male"
                            : businessDetails?.gender == "Male"
                            ? "male"
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <LocationOnOutlined style={{ color: "red" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.village}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails.village_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <LocalHospitalOutlined style={{ color: "maroon" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.blood_group}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails.blood_group}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <WorkOutlineOutlined
                          style={{ color: getIconColor(Strings.address) }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.occupation}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails.occupation_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <BusinessOutlined style={{ color: "darkgray" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.company_name}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails.company_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <PublicOutlined style={{ color: "darkslateblue" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.designation}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails.designation}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <WorkOutlineOutlined style={{ color: "green" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.occupation_address}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails.occupation_address}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <FitnessCenterOutlined
                          style={{ color: "saddlebrown" }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.weight}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails.weight}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {businessDetails.annual_income && (
                    <Grid item xs={12}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={2}>
                          <MonetizationOnOutlined
                            style={{ color: "darkgoldenrod" }}
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant="subtitle1">
                            <strong>{Strings.annual_income}</strong>
                          </Typography>
                          <Typography variant="body2">
                            {businessDetails.annual_income}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <FavoriteOutlined style={{ color: "rosybrown" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.matrimonial_interest}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails.matrimonial_status === "2"
                            ? "Yes"
                            : "No"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <SubjectOutlined
                          style={{ color: getIconColor(Strings.country) }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.about_me}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails.about_me}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <CallIcon
                          style={{ color: getIconColor(Strings.description) }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.contact}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails.gender == "female"
                            ? ""
                            : businessDetails.contact}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <ChildFriendlyOutlined style={{ color: "navy" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.age_colon}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails.age}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <SchoolOutlined style={{ color: "darkcyan" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.education_name}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails.education_name == null
                            ? ""
                            : businessDetails.education_name +
                                "/" +
                                businessDetails.education_sub_name ==
                              null
                            ? ""
                            : businessDetails.education_sub_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <LocationOnOutlined style={{ color: "darkkhaki" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.residence_address}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails.residence_address}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <LocalHospitalOutlined style={{ color: "maroon" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.InterestBloodDonation}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails.blood_donate_status == 2
                            ? "Yes"
                            : "No"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <LocationCityOutlined
                          style={{ color: "darkmagenta" }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.city_location}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails.contact_2}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <PublicOutlined style={{ color: "darkolivegreen" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.current_country}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails.country_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {businessDetails.country_name == "India" ? (
                    ""
                  ) : (
                    <Grid item xs={12}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={2}>
                          <CardMembershipOutlined
                            style={{ color: "darkturquoise" }}
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant="subtitle1">
                            <strong>{Strings.visa_type}</strong>
                          </Typography>
                          <Typography variant="body2">
                            {businessDetails.visa_type_name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <FamilyRestroomOutlined
                          style={{ color: "lightsteelblue" }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.mosal}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails.mosal}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <FaceOutlined style={{ color: "lawngreen" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.mothername}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails.mother_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <FaceOutlined style={{ color: "darkviolet" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.mama_name}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails.m_uncle_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <FavoriteBorderOutlined style={{ color: "darkred" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.marital_status}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails.marital_status == "1"
                            ? Strings.Single
                            : businessDetails.marital_status == "2"
                            ? Strings.Married
                            : businessDetails.marital_status == "3"
                            ? Strings.Widowed
                            : businessDetails.marital_status == "4"
                            ? Strings.Engaged
                            : businessDetails.marital_status == "5"
                            ? Strings.Separated
                            : businessDetails.marital_status == "6"
                            ? Strings.Divorced
                            : businessDetails.marital_status == "7"
                            ? Strings.Widower
                            : businessDetails.marital_status == "8"
                            ? Strings.NotInterested
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <AccessibilityNewOutlined
                          style={{ color: "lightsteelblue" }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          <strong>{Strings.height}</strong>
                        </Typography>
                        <Typography variant="body2">
                          {businessDetails.height}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Box textAlign="center">
              <img
                src={nodatafound}
                alt="No Data Found"
                style={{ width: "500px", height: "auto" }}
              />
            </Box>
          )}
        </Box>
      </Card>

      <Box mt={4} mb={6}>
        <Typography
          variant="h5"
          style={{ marginBottom: "16px", paddingLeft: "24px" }}
        >
          {familyDe?.length > 0 ? "Family Members" : ""}
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          {familyDe?.length > 4 && (
            <IconButton onClick={handlePrevPage}>
              <KeyboardArrowLeftIcon />
            </IconButton>
          )}
          <Grid container spacing={3}>
            {familyDe &&
              familyDe
                .slice(page * pageSize, page * pageSize + pageSize)
                .map((member, index) => (
                  <Grid item xs={12} sm={4} key={index}>
                    <Card
                      style={{ padding: "16px", textAlign: "center" }}
                      onClick={() => handleViewProfile(member)}
                    >
                      <Box display="flex" justifyContent="center" mb={2}>
                        <Avatar
                          alt={member.name}
                          src={Imageurl.member_image + member.photo}
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                        >
                          {defaultProfileImage}
                        </Avatar>
                      </Box>
                      <Typography variant="h6" gutterBottom>
                        {member.first_name + " " + member.surname}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        {member.relation_name}
                      </Typography>
                    </Card>
                  </Grid>
                ))}
          </Grid>
          {familyDe?.length > (page + 1) * pageSize && (
            <IconButton onClick={handleNextPage}>
              <KeyboardArrowRightIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    </div>
  );
}

export default MemberDetail;
